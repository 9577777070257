import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

import Auth from '@components/profile/Auth'

export default function Signup() {
	const { status } = useSession()
	const router = useRouter()

	// Redirect once/if the user is signed in
	if (status === 'authenticated') {
		const url = (router?.query?.continueUrl as string) || '/profile'
		console.log(router?.query?.continueUrl)

		router.push(url)
		return null
	}

	return (
		<div className="flex justify-center py-10">
			<div>
				<h1 className="text-3xl text-center">Hello, beautiful.</h1>

				{/* Description */}
				<p className="mt-4 mb-10 font-medium text-center text-gray-500 max-md:text-sm">
					Log into your account
				</p>

				<Auth subscriptionBox step="login" />
			</div>
		</div>
	)
}
